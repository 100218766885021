<template>
  <!-- @shown gets triggered once the tooltip appears -->
  <button
    :class="['vidjet-button-video', buttonShape]"
    :style="buttonColour"
    @click="callToAction"
    v-tooltip="{
      text: $t('player.copyCta.tooltip'),
      trigger: 'click',
      shouldShowTooltip: showTooltip,
    }"
  >
    <slot />
  </button>
</template>

<script>
import enums from "@/enums.js";
import { decodeText } from "../../utils/utils";

import { mapGetters, mapActions } from "vuex";

const { CtaType } = enums;
export default {
  data() {
    return {
      hasClickedCopy: false,
    };
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters("player", ["isInPreview", "isMobile"]),
    // might be better to fetch with id
    ...mapGetters("videos", ["getCtaByIndex"]),
    ...mapGetters("playerCampaign", ["format"]),

    buttonColour() {
      if (this.cta.buttonColor) {
        return `background-color:${this.format.cta.buttonColor}; color:${this.format.cta.textColor}`;
      } else {
        return "";
      }
    },

    cta() {
      return this.getCtaByIndex(this.videoIndex);
    },

    showTooltip() {
      return Boolean(this.cta) && this.cta.ctaType === CtaType.copy;
    },
    buttonShape() {
      if (this.format.cta.buttonShape) {
        return `border-${this.format.cta.buttonShape}`;
      } else {
        return "border-semi";
      }
    },
  },

  methods: {
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),
    callToAction() {
      if (!this.isInPreview) {
        this.sendEvent({
          type: "ctaButtonClick",
        });
      }
      if (this.cta.ctaType === CtaType.link && Boolean(this.cta.value)) {
        window.open(this.cta.value, "_blank");
        this.$emit("pause-video");
      } else if (this.cta.ctaType === CtaType.copy && Boolean(this.cta.value)) {
        navigator.clipboard.writeText(decodeText(this.cta.value));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vidjet-button-video {
  font-size: 16px;
  border-style: inherit;
  font-family: "Gothic A1", sans-serif;
  border: none;
  line-height: 22px;
  outline: none;
  padding: 4px 16px 4px 16px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  transition: $button-transition;
  &:hover {
    transition: $button-transition;
    transform: scale(1.1);
  }
  svg {
    cursor: pointer;
  }
  @include media(">phone", "<=tablet") {
    padding: 4px 12px;
    font-size: 14px;
  }
}

.border-square {
  border-radius: 1px;
}
.border-semi {
  border-radius: 8px;
}
.border-round {
  border-radius: 100px;
}

.isMobile.isPortrait {
  .vidjet-button-video {
    line-height: 16px;
    padding: 4px 8px 4px 8px;
    font-size: 14px;
  }
}
</style>
